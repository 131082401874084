/* Base Styles */
body {
    font-family: 'Inter', sans-serif;
    margin: 0;
    padding: 0;
    background: linear-gradient(90deg, #000639 0%, #1C267D 100%);
    color: #FFFFFF;
}

#Menu {
    position: absolute;
    width: 12%;
    right: 5%;
    cursor: pointer;
}

/* Full-screen overlay menu styles */
@media (max-width: 990px) {
    #navMenu {
        display: none;
        position: fixed;
        top: 0;
        right: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(255, 255, 255, 0.95);
        flex-direction: column;
        justify-content: center;
        align-items: center;
        z-index: 1000;
        transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;
        opacity: 0;
        transform: translateY(-100%);
    }

    #navMenu a {
        margin: 20px 0;
        font-size: 24px;
        color: black;
        text-decoration: none;
        font-family: 'Syne', sans-serif;
    }

    #navMenu a:hover {
        color: #1C267D;
    }

    #closeMenu {
        position: absolute;
        top: 30px;
        right: 30px;
        font-size: 60px;
        cursor: pointer;
        color: #1C267D;
    }
}

@media (min-width: 990px) {
    #Menu {
        display: none;
    }

    #closeMenu {
        display: none;
    }
}


/* Styles for when the menu is toggled */
#navMenu.active {
    display: flex;
    opacity: 1;
    transform: translateY(0);
}


/* Show the nav on larger screens */
@media (min-width: 769px) {
    #navMenu {
        display: flex;
    }
}

/* Container for Ellipses */
.container {
    position: relative;
    overflow: hidden;
    width: 90%;
    margin: 0 auto;
}

.ellipse {
    position: absolute;
    width: 20vw;
    height: 20vw;
    background: #1C267D;
    filter: blur(100px);
    border-radius: 50%;
    z-index: -1;
    /* Ensure ellipses are behind content */
}

.ellipse-1 {
    left: 0;
    top: 20%;
}

.ellipse-2 {
    left: 0;
    top: 80%;
}

.ellipse-3 {
    right: 0;
    top: 55%;
}

.ellipse-4 {
    left: 0;
    top: 150%;
}

.ellipse-5 {
    right: 0;
    top: 170%;
}

.ellipse-6 {
    left: 0;
    top: 200%;
}

.ellipse-7 {
    right: 0;
    top: 275%;
}

.ellipse-8 {
    left: 0;
    top: 300%;
}

.ellipse-9 {
    right: 0;
    top: 350%;
}

.ellipse-10 {
    left: 0;
    top: 375%;
}

.ellipse-11 {
    right: 0;
    top: 440%;
}

.ellipse-12 {
    left: 0;
    top: 440%;
}

/* Media Queries for Responsive Design */
@media (max-width: 1200px) {
    .ellipse {
        filter: blur(80px);
        width: 25vw;
        height: 25vw;
    }

    .ellipse-1 {
        left: 0;
        top: 20%;
    }

    .ellipse-2 {
        right: 0;
        top: 40.91%;
    }

    .ellipse-3 {
        left: 0;
        top: 61.82%;
    }

    .ellipse-4 {
        right: 0;
        top: 82.73%;
    }

    .ellipse-5 {
        left: 0;
        top: 103.64%;
    }

    .ellipse-6 {
        right: 0;
        top: 124.55%;
    }

    .ellipse-7 {
        left: 0;
        top: 145.45%;
    }

    .ellipse-8 {
        right: 0;
        top: 166.36%;
    }

    .ellipse-9 {
        left: 0;
        top: 187.27%;
    }

    .ellipse-10 {
        right: 0;
        top: 208.18%;
    }

    .ellipse-11 {
        left: 0;
        top: 229.09%;
    }

    .ellipse-12 {
        right: 0;
        top: 250%;
    }

}

@media (max-width: 768px) {
    .ellipse {
        filter: blur(70px);
        width: 30vw;
        height: 30vw;
    }

    .ellipse-1 {
        left: 0;
        top: 20%;
    }

    .ellipse-2 {
        left: 0;
        top: 80%;
    }

    .ellipse-3 {
        right: 0;
        top: 55%;
    }

    .ellipse-4 {
        left: 0;
        top: 120%;
    }

    .ellipse-5 {
        right: 0;
        top: 160%;
    }

    .ellipse-6 {
        left: 0;
        top: 200%;
    }

    .ellipse-7 {
        right: 0;
        top: 240%;
    }

    .ellipse-8 {
        left: 0;
        top: 280%;
    }

    .ellipse-9 {
        right: 0;
        top: 220%;
    }

    .ellipse-10 {
        left: 0;
        top: 260%;
    }

    .ellipse-11 {
        right: 0;
        top: 290%;
    }

    .ellipse-12 {
        left: 0;
        top: 295%;
    }
}

@media (max-width: 480px) {
    .ellipse {
        filter: blur(45px);
        width: 40vw;
        height: 40vw;
    }
}

/* Header Styles */
header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 40px;
    padding-right: 10%;
}

.logo img {
    width: 100%;
    /* Logo width relative to the logo container */
    height: auto;
    /* Maintain aspect ratio of the logo */
    animation: fadeIn 1s ease-out;
}

nav a {
    color: #E0E0E0;
    text-decoration: none;
    margin-left: 50px;
    font-size: 15px;
}

/* Main Content Styles */
main {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 100px 100px;
}

.background {
    z-index: -99;
    background: url('./image/background.svg') no-repeat center center;
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 100px 100px;
}

.background2 {
    z-index: -99;
    background: url('./image/background2.svg') no-repeat center center;
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 100px 100px;
}

h1,
h2,
h3 {
    font-family: 'Syne', sans-serif;
}

h1 {
    font-size: 60px;
    text-align: center;
    margin: 0;
    padding-top: 100px;
    margin-bottom: 100px;
}

.heading {
    padding-top: 200px !important;
    animation: fadeInUp 0.8s ease-out;
}

@keyframes fadeInUp {
    from {
        opacity: 0;
        transform: translateY(20px);
    }

    to {
        opacity: 1;
        transform: translateY(0);
    }
}

/* Responsive adjustments */
@media (max-width: 1200px) {
    .heading {
        padding-top: 180px !important;
    }
}

@media (max-width: 992px) {
    .heading {
        padding-top: 150px !important;
    }
}

@media (max-width: 768px) {
    .heading {
        padding-top: 120px !important;
    }
}

@media (max-width: 576px) {
    .heading {
        padding-top: 100px !important;
    }
}

/* Contact Section Styles */
.contact-container {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    /* Added to ensure wrapping on smaller screens */
}

.message-section,
.form-section {
    width: 45%;
}

.message-section h2 {
    font-size: 24px;
    margin-bottom: 20px;
}

.privecy-section {
    width: 90%;
}

/* Responsive Styles */
@media (max-width: 1200px) {
    main {
        padding: 0 50px 50px;
    }

    .background,
    .background2 {
        padding: 0 50px 50px;
    }
}

@media (max-width: 992px) {
    header {
        flex-direction: column;
        align-items: flex-start;
        padding-right: 40px;
    }

    nav a {
        margin-left: 20px;
        font-size: 14px;
    }

    h1 {
        font-size: 50px;
        padding-top: 80px;
        margin-bottom: 80px;
    }

    .contact-container {
        flex-direction: column;
        align-items: center;
    }

    .message-section,
    .form-section {
        width: 100%;
        margin-bottom: 20px;
        /* Added to give space between sections */
    }
}

@media (max-width: 768px) {
    main {
        padding: 0 20px 20px;
    }

    .background,
    .background2 {
        padding: 0 20px 20px;
    }

    h1 {
        font-size: 40px;
        padding-top: 60px;
        margin-bottom: 60px;
    }
}

@media (max-width: 576px) {
    header {
        padding: 10px 20px;
    }


    .logo img {
        padding-top: 10%;
        width: 60%;
        height: auto;
        transition: all 0.3s ease;
    }

    nav a {
        margin-left: 10px;
        font-size: 12px;
    }

    h1 {
        font-size: 30px;
        padding-top: 40px;
        margin-bottom: 40px;
    }
}

/* Form Styles */
form input,
form textarea {
    width: 70%;
    padding: 15px;
    margin-bottom: 20px;
    border: none;
    border-radius: 8px;
    background-color: #FFFFFF;
}

form textarea {
    height: 150px;
    resize: vertical;
}

button {
    background: rgb(15, 15, 15);
    border: 1px solid white;
    padding: 15px 20px;
    border-radius: 10px;
    cursor: pointer;
    transition: all 0.3s ease;
    animation: slideIn 0.5s ease-out 0.7s both, glow 2s infinite;
    font-weight: 700;
    font-size: 14px;
    color: #FFFFFF;
}

button:hover {
    background: #FFFFFF;
    color: rgb(15, 15, 15);
}

/* Footer Styles */
footer {
    background: linear-gradient(90deg, #000000 0%, #1C267D 100%);
    padding: 40px 77px;
    border-radius: 25px 25px 0 0;
    box-shadow: 0px -5px 10px rgba(0, 0, 0, 0.25);
}

.footer-content {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;
    /* Added to ensure wrapping on smaller screens */
}

.footer-logo {
    width: 48px;
    height: 48px;
    margin-bottom: 20px;
}

.newsletter h3 {
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 5px;
}

.newsletter p {
    font-size: 15px;
    margin-bottom: 20px;
}

.newsletter form {
    display: flex;
    align-items: left;
    flex-wrap: wrap;
    /* Added to ensure wrapping on smaller screens */
}

.newsletter input {
    width: 100%;
    /* Adjusted for responsiveness */
    height: 43px;
    padding: 10px 10px 10px 44px;
    border: none;
    border-radius: 10px;
    background: #EAECF0;
    font-size: 16px;
    margin-bottom: 10px;
    /* Added margin for spacing on smaller screens */
}

.footer-links {
    font-size: 14px;
    color: #CDCDCD;
    margin-top: 20px;
}

.social-icons {
    display: flex;
    gap: 16px;
}

.social-icons a img {
    width: 40px;
    height: 40px;
}

.buttons .add-to-chrome img {
    width: 15%;
    height: 15%;
}

/* Responsive Styles */
@media (max-width: 1200px) {
    main {
        padding: 0 50px 50px;
    }

    .background,
    .background2 {
        padding: 0 50px 50px;
    }

    .social-icons a img {
        width: 30px;
        height: 30px;
    }
}

@media (max-width: 992px) {
    header {
        flex-direction: column;
        align-items: flex-start;
        padding-right: 40px;
    }

    nav a {
        margin-left: 20px;
        font-size: 14px;
    }

    h1 {
        font-size: 50px;
        padding-top: 80px;
        margin-bottom: 80px;
    }

    .contact-container {
        flex-direction: column;
        align-items: center;
    }

    .message-section,
    .form-section {
        width: 100%;
        margin-bottom: 20px;
        /* Added to give space between sections */
    }

    .social-icons a img {
        width: 30px;
        height: 30px;
    }
}

@media (max-width: 768px) {
    main {
        padding: 0 20px 20px;
    }

    .background,
    .background2 {
        padding: 0 30px 20px;
    }

    h1 {
        font-size: 40px;
        padding-top: 60px;
        margin-bottom: 60px;
    }

    footer {
        padding: 30px 20px;
    }

    .footer-content {
        flex-direction: column;
        align-items: center;
    }

    .newsletter form {
        flex-direction: column;
        align-items: stretch;
    }

    .newsletter input {
        width: 100%;
    }

    .social-icons {
        justify-content: center;
        gap: 10px;
    }
}

@media (max-width: 576px) {
    header {
        padding: 10px 20px;
    }



    nav a {
        margin-left: 10px;
        font-size: 12px;
    }

    h1 {
        font-size: 30px;
        padding-top: 40px;
        margin-bottom: 40px;
    }

    footer {
        padding: 20px 10px;
    }

    .newsletter h3 {
        font-size: 18px;
    }

    .newsletter p {
        font-size: 14px;
    }

    .newsletter input {
        font-size: 14px;
    }

    .social-icons a img {
        width: 20px;
        height: 20px;
    }
}

/* Content Section */
.content {
    text-align: center;
}

.content h1 {
    padding: 100px 0 0;
    margin: 0 0 20px;
}

.buttons,
.add-to-chrome,
.free-trial {
    display: flex;
    cursor: pointer;
    align-items: center;
    justify-content: center;
}

.free-trial {
    margin: 5%;
}

.video-container {
    position: relative;
    padding-bottom: 56.25%;
    /* 16:9 aspect ratio */
    height: 0;
    overflow: hidden;
    max-width: 100%;
    background: rgba(0, 0, 0, 0.5);
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
}

.video-container iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: none;
}

.benefits {
    color: white;
    padding-left: 10%;
    padding-right: 10%;
    font-family: Arial, sans-serif;
}

.benefits-grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 1rem;
}

.benefit-item {
    background-color: rgb(15, 15, 15);
    padding: 10%;
    border-radius: 8px;
}

.benefit-item img {
    width: 40px;
    height: 40px;
    margin-bottom: 0.5rem;
}

.benefit-item h3 {
    font-size: 1rem;
    margin-bottom: 0.5rem;
}

.benefit-item p {
    font-size: 0.9rem;
    line-height: 1.4;
}

/* Responsive Styles */
@media (max-width: 1200px) {
    .content h1 {
        padding: 80px 0 0;
        margin-bottom: 15px;
    }

    .benefits {
        padding-left: 5%;
        padding-right: 5%;
    }

    .benefits-grid {
        grid-template-columns: repeat(3, 1fr);
    }
}

@media (max-width: 992px) {
    .content h1 {
        padding: 60px 0 0;
        margin-bottom: 10px;
        font-size: 50px;
    }

    .benefits {
        padding-left: 3%;
        padding-right: 3%;
    }

    .benefits-grid {
        grid-template-columns: repeat(2, 1fr);
    }

    .free-trial {
        flex-direction: column;
    }
}

@media (max-width: 768px) {
    .content h1 {
        padding: 40px 0 0;
        margin-bottom: 10px;
        font-size: 40px;
    }

    .benefits {
        padding-left: 2%;
        padding-right: 2%;
    }

    .benefits-grid {
        grid-template-columns: 1fr;
    }

    .free-trial {
        margin: 8%;
    }
}

@media (max-width: 576px) {
    .content h1 {
        font-size: 30px;
    }

    .benefits {
        padding-left: 1%;
        padding-right: 1%;
    }

    .benefit-item {
        padding: 5%;
    }

    .benefit-item h3 {
        font-size: 0.9rem;
    }

    .benefit-item p {
        font-size: 0.8rem;
    }
}

/* Pricing Section */
.pricing-container {
    color: rgb(255, 255, 255);
    padding: 5% 10% 10%;
    font-family: Arial, sans-serif;
}

.pricing-container h2 {
    text-align: center;
    font-size: 2.5rem;
    margin-bottom: 0.5rem;
}

.pricing-cards {
    display: flex;
    justify-content: space-between;
    gap: 1rem;
    flex-wrap: wrap;
    /* Added to ensure wrapping on smaller screens */
}

.pricing-card {
    background-color: rgb(255, 255, 255);
    color: rgb(15, 15, 15);
    border-radius: 10px;
    padding: 4%;
    width: 20%;
    position: relative;
    margin: 1%;
}

.pricing-card h3 {
    font-size: 1.5rem;
    margin-bottom: 0.5rem;
}

.description {
    font-size: 0.9rem;
    color: #666;
    margin-bottom: 1rem;
}

.price {
    font-size: 2rem;
    font-weight: bold;
    margin-bottom: 1rem;
}

.price-subtitle {
    font-size: 0.8rem;
    font-weight: normal;
}

ul {
    list-style-type: none;
    padding: 1%;
    margin-bottom: 1rem;
}

li {
    margin-bottom: 3%;
    position: relative;
    padding-left: 1.5rem;
}

li::before {
    content: "✓";
    position: absolute;
    left: 0;
    color: #000080;
}

.sign-up {
    width: 100%;
    font-size: 1rem;
    cursor: pointer;
    border-color: rgb(15, 15, 15);
}

.popular {
    background-color: #666;
    color: white;
    padding: 0.25rem 0.5rem;
    border-radius: 15px;
    font-size: 0.8rem;
    position: absolute;
    top: 1rem;
    right: 1rem;
}

/* Responsive Styles */
@media (max-width: 1200px) {
    .pricing-card {
        width: 45%;
        /* Adjusted width for two-column layout */
    }
}

@media (max-width: 992px) {
    .pricing-card {
        width: 45%;
        /* Adjusted width for two-column layout */
        margin: 2% 0;
        /* Adjusted margin for spacing */
    }

    .pricing-container h2 {
        font-size: 2rem;
    }
}

@media (max-width: 768px) {
    .pricing-container {
        padding: 5% 5% 10%;
    }

    .pricing-card {
        width: 100%;
        /* Adjusted width for single-column layout */
    }

    .pricing-container h2 {
        font-size: 1.8rem;
    }

    .pricing-card h3 {
        font-size: 1.3rem;
    }

    .price {
        font-size: 1.8rem;
    }

    .price-subtitle {
        font-size: 0.7rem;
    }

    .description {
        font-size: 0.8rem;
    }

    .sign-up {
        font-size: 0.9rem;
    }
}

@media (max-width: 576px) {
    .pricing-container {
        padding: 5% 2% 10%;
    }

    .pricing-container h2 {
        font-size: 1.5rem;
    }

    .pricing-card h3 {
        font-size: 1.2rem;
    }

    .price {
        font-size: 1.5rem;
    }

    .price-subtitle {
        font-size: 0.6rem;
    }

    .description {
        font-size: 0.7rem;
    }

    .sign-up {
        font-size: 0.8rem;
    }
}

.testimonial-section {
    color: #fff;
    text-align: center;
}



.testimonial-row {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 20px;
}

.testimonial {
    background: rgb(15, 15, 15);
    padding: 20px;
    margin: 10px;
    border-radius: 8px;
    width: calc(50% - 20px);
    text-align: left;
    flex: 1 1 300px;
}

.client-info {
    display: flex;
    align-items: center;
}

.client-info img {
    border-radius: 50%;
    margin-right: 15px;
    height: 30px;
    width: 30px;
}

.client-info div {
    text-align: left;
}

.client-info strong {
    font-size: 18px;
    display: block;
}

.client-info span {
    font-size: 14px;
    color: #ddd;
}

@media (max-width: 768px) {
    .container {
        width: 95%;
    }

    .testimonial {
        width: 100%;
        margin: 10px 0;
    }

    .client-info strong {
        font-size: 16px;
    }

    .client-info span {
        font-size: 12px;
    }
}

@media (max-width: 480px) {
    .container {
        padding: 0 10px;
    }

    .testimonial {
        padding: 15px;
    }

    .client-info img {
        height: 25px;
        width: 25px;
    }

    .client-info strong {
        font-size: 14px;
    }

    .client-info span {
        font-size: 11px;
    }
}

/* Additional styles for login page */
.form-options {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    font-size: 14px;
}

.forgot-password {
    color: #f7f7fa;
}

.btn-google {
    background-color: rgb(15, 15, 15);
    color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
}

/* Adjust existing styles */
.contact-container {
    border-radius: 10px;
    overflow: hidden;
}

@media (max-width: 768px) {

    .message-section,
    .form-section {
        padding: 40px;
    }

    .message-section p {
        padding-right: 15%;
    }

    .message-section h2 {
        padding-right: 15%;
    }

}

.message-section,
.form-section {
    padding: 0px;
}

.form-section form button {
    margin-bottom: 15px;
}

#h2gemini {
    margin-top: 0px;
}

/* Hide the default file input */
input[type="file"] {
    display: none;
}

/* Style the custom file upload button */
.custom-file-upload {
    font-family: "Inter", sans-serif;
    border: 1px solid rgb(255, 255, 255);
    display: inline-block;
    padding: 15px 20px;
    cursor: pointer;
    background-color: rgb(15, 15, 15);
    color: white;
    border-radius: 10px;
    font-weight: 700;
    font-size: 14px;
    transition: background-color 0.3s ease, transform 0.2s ease;
}

/* Add a hover effect */
.custom-file-upload:hover {
    background-color: #ffffff;
    transform: scale(1.05);
    color: rgb(15, 15, 15);
    border: 1px solid rgb(236, 214, 214);
}

/* Style the file name display */
.file-name {
    margin-left: 15px;
    opacity: 0;
    transition: opacity 0.5s ease;
}

/* Responsive adjustments */
@media (max-width: 768px) {

    .message-section,
    .form-section {
        padding: 30px;
    }

    .form-options {
        flex-direction: column;
        align-items: flex-start;
    }

    .form-options>* {
        margin-bottom: 10px;
    }

    .custom-file-upload {
        padding: 12px 16px;
        font-size: 13px;
    }
}

@media (max-width: 480px) {

    .message-section,
    .form-section {
        padding: 20px;
    }

    .custom-file-upload {
        padding: 10px 14px;
        font-size: 12px;
    }

    .file-name {
        margin-left: 10px;
    }
}


/* new chages */

/* Existing CSS code... */

/* Enhanced Responsive Styles */
@media (max-width: 1200px) {
    /* Existing styles... */

    .ellipse {
        transition: all 0.3s ease;
    }
}

@media (max-width: 992px) {
    /* Existing styles... */

    nav {
        transition: all 0.3s ease;
    }

    .contact-container {
        transition: all 0.3s ease;
    }
}

@media (max-width: 768px) {
    /* Existing styles... */

    h1 {
        transition: all 0.3s ease;
    }

    .footer-content {
        transition: all 0.3s ease;
    }
}


/* Animations */
@keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

@keyframes slideInFromLeft {
    from {
        transform: translateX(-50px);
        opacity: 0;
    }

    to {
        transform: translateX(0);
        opacity: 1;
    }
}

@keyframes slideInFromRight {
    from {
        transform: translateX(50px);
        opacity: 0;
    }

    to {
        transform: translateX(0);
        opacity: 1;
    }
}

@keyframes scaleIn {
    from {
        transform: scale(0.9);
        opacity: 0;
    }

    to {
        transform: scale(1);
        opacity: 1;
    }
}

@keyframes float {
    0% {
        transform: translateY(0px);
    }

    50% {
        transform: translateY(-10px);
    }

    100% {
        transform: translateY(0px);
    }
}



nav a {
    animation: slideInFromRight 0.5s ease-out;
    animation-fill-mode: backwards;
}

nav a:nth-child(1) {
    animation-delay: 0.1s;
}

nav a:nth-child(2) {
    animation-delay: 0.2s;
}

nav a:nth-child(3) {
    animation-delay: 0.3s;
}

nav a:nth-child(4) {
    animation-delay: 0.4s;
}

h1 {
    animation: slideInFromLeft 1s ease-out;
}

.ellipse {
    animation: float 6s ease-in-out infinite;
}

.contact-container,
.pricing-card,
.testimonial,
.benefit-item {
    animation: scaleIn 0.5s ease-out;
}

button,
.custom-file-upload {
    transition: all 0.3s ease;
}

button:hover,
.custom-file-upload:hover {
    transform: translateY(-3px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

/* Improved responsiveness for pricing cards */
@media (max-width: 1200px) {
    .pricing-card {
        width: 20%;
        margin: 10px;
    }
}

@media (max-width: 768px) {
    .pricing-card {
        width: 100%;
        margin: 10px 0;
    }
}

/* Better responsive layout for benefits grid */
@media (max-width: 992px) {
    .benefits-grid {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media (max-width: 576px) {
    .benefits-grid {
        grid-template-columns: 1fr;
    }
}

/* Smooth transitions for responsive changes */
.benefits-grid,
.pricing-cards,
.testimonial-row {
    transition: all 0.3s ease;
}

/* Enhance form responsiveness */
@media (max-width: 576px) {

    form input,
    form textarea,
    .custom-file-upload {
        font-size: 14px;
    }
}

/* Improve footer responsiveness */
@media (max-width: 768px) {
    .footer-content>* {
        width: 100%;
        margin-bottom: 20px;
    }

    .social-icons {
        justify-content: flex-start;
    }
}

/* Add smooth scrolling for better user experience */
html {
    scroll-behavior: smooth;
}

/* Enhance accessibility with focus styles */
a:focus,
button:focus,
input:focus,
textarea:focus {
    outline: 2px solid #4a90e2;
    outline-offset: 2px;
}

/* Improve readability on smaller screens */
@media (max-width: 576px) {
    body {
        font-size: 14px;
    }

    h1 {
        font-size: 28px;
    }

    h2 {
        font-size: 24px;
    }

    h3 {
        font-size: 20px;
    }
}


img {
    height: 10%;
    width: 10%;
}

/* about css */


.hero {
    color: #fff;
    text-align: center;
    padding: 5rem 2rem;
}

.hero h1 {
    font-size: 3rem;
    margin-bottom: 1rem;
}

.hero p {
    font-size: 18px;
    max-width: 80%;
    margin: 0 auto;
}

.about-content {
    display: flex;
    justify-content: space-between;
    padding: 5rem 10%;
}

.mission,
.values {
    flex-basis: 45%;
}



.values ul {
    list-style-type: none;
}

.values ul li {
    padding: 0.5rem 0;
    position: relative;
    padding-left: 1.5rem;
}

.values ul li::before {
    content: "•";
    color: #3498db;
    font-size: 1.5rem;
    position: absolute;
    left: 0;
    top: 0.3rem;
}



.team-members {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    margin-top: 3rem;
}

.member {
    background-color: #fff;
    border-radius: 10px;
    padding: 2rem;
    margin: 1rem;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease;
}

.member:hover {
    transform: translateY(-5px);
}

.member img {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    object-fit: cover;
    margin-bottom: 1rem;
}

.member h3 {
    font-size: 1.2rem;
    margin-bottom: 0.5rem;
}

.member p {
    color: #777;
}

footer {
    background-color: #333;
    color: #fff;
    text-align: left;
    padding: 2%;
}

@media (max-width: 768px) {
    .about-content {
        flex-direction: column;
    }

    .mission,
    .values {
        margin-bottom: 2rem;
    }

    .team-members {
        flex-direction: column;
        align-items: center;
    }
}


/* Add this to your existing CSS file */

@import url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.0.0-beta3/css/all.min.css');

.team {
    text-align: center;
}

.team h2 {
    font-size: 2.5rem;
    margin-bottom: 3rem;
}

.team-members {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 2rem;
}

.member-card {
    width: 300px;
    height: 400px;
    perspective: 1000px;
}

.card-inner {
    width: 100%;
    height: 100%;
    transition: transform 0.8s;
    transform-style: preserve-3d;
    cursor: pointer;
}

.member-card:hover .card-inner {
    transform: rotateY(180deg);
}

.card-front,
.card-back {
    position: absolute;
    width: 100%;
    height: 100%;
    backface-visibility: hidden;
    border-radius: 15px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.card-front {
    background-color: #000639;
    padding: 0px;
}

.card-back {
    background-color: #000639;
    color: #fff;
    transform: rotateY(180deg);
}

.card-front img {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    object-fit: cover;
    margin-bottom: 1rem;
}

.card-front h3,
.card-back h3 {
    font-size: 1.5rem;
    margin-bottom: 0.5rem;
}

.card-front p {
    color: #777;
}

.card-back p {
    margin-bottom: 1rem;
}

.skills {
    list-style-type: none;
    padding: 0;
    margin-bottom: 1rem;
}

.skills li {
    background-color: rgba(255, 255, 255, 0.2);
    padding: 0.3rem 0.8rem;
    border-radius: 20px;
    display: inline-block;
    margin: 0.2rem;
    font-size: 0.9rem;
}

.social-links {
    margin-top: 1rem;
}

.social-links a {
    color: #fff;
    font-size: 1.5rem;
    margin: 0 0.5rem;
    transition: color 0.3s ease;
}

.social-links a:hover {
    color: #f1c40f;
}

@media (max-width: 768px) {
    .team-members {
        flex-direction: column;
        align-items: center;
    }

    .member-card {
        width: 100%;
        max-width: 300px;
        margin-bottom: 2rem;
    }
}



/* blog css */
.blog-list {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
}

.blog-post {
    background: #E3E6FF;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.blog-post img {
    padding: 10%;
    width: 80%;
    height: 200px;
    object-fit: cover;
}

.post-content {
    padding: 20px;
}



.read-more {
    color: #1C267D;
    text-decoration: none;
    font-weight: bold;
}

/* Blog Post Page Styles */
.blog-post-full img {
    padding-top: 10%;
    width: 80%;
    height: 400px;
    object-fit: cover;
    border-radius: 10px 10px 0 0;
}


.post-date {
    font-size: 14px;
    color: #fff;
    margin-bottom: 20px;
}

.post-content {
    font-size: 18px;
    line-height: 1.6;
    color: #1C267D;
    margin-bottom: 40px;
}



/* Responsive Styles */
@media (max-width: 1024px) {
    .blog-list {
        grid-template-columns: repeat(2, 1fr);
    }

    .blog-post-full img {
        height: 300px;
    }

    main {
        padding: 15px;
    }

    h1 {
        font-size: 30px;
    }

    .post-content {
        font-size: 17px;
    }
}

@media (max-width: 768px) {
    .blog-list {
        grid-template-columns: 1fr;
    }

    header nav {
        justify-content: space-around;
    }

    main {
        padding: 10px;
    }

    h1 {
        font-size: 28px;
    }

    .post-content {
        font-size: 16px;
    }

    .blog-post img {
        height: 150px;
    }
}

@media (max-width: 480px) {
    header {
        flex-direction: column;
    }

    header nav a {
        margin-left: 0;
        margin-bottom: 5px;
    }

    h1 {
        font-size: 24px;
    }

    .post-content {
        font-size: 15px;
    }

    .blog-post img {
        height: 120px;
    }

    .blog-post-full img {
        height: 250px;
    }
}



/* Referral.css */
.referral-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #0513d7;
}

.referral-box {
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    padding: 20px;
    text-align: center;
    width: 80%;
    max-width: 400px;
    margin: auto;
}

.referral-url {
    font-size: 1.2rem;
    color: #333;
    margin-bottom: 10px;
    word-wrap: break-word;
}

.copy-btn {
    background-color: #4caf50;
    color: white;
    padding: 10px 15px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1rem;
}

.copy-btn:hover {
    background-color: #45a049;
}

.copy-message {
    display: block;
    margin-top: 10px;
    color: #28a745;
    font-weight: bold;
}

/* src/ProfileSection.css */
.profile-section {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    /* Align to the right */
    padding: 10px;
}

.user-info {
    display: flex;
    align-items: center;
}

.profile-image,
.profile-initial {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    /* Make it a circle */
    background-color: #007bff;
    /* Fallback color */
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    margin-right: 10px;
}

.profile-image {
    object-fit: cover;
}

.logout-button,
.login-button {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 5px 10px;
    border-radius: 4px;
    cursor: pointer;
}

.logout-button:hover,
.login-button:hover {
    background-color: #0056b3;
}





.referal-img {
    width: auto !important;
    /* Overrides any inherited percentage widths */
    max-width: none;
    /* Prevents scaling based on container */
    height: auto;
    /* Maintains aspect ratio */
    padding-left: 100px;
}

/* Mobile adjustments */
@media (max-width: 600px) {
    .referal-img {
        padding-left: 20px;
    }
}


/* General reset */

.wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    /* Full viewport height */
}

/* Main container styling for content area */
.content-container {
    width: 70%;
    background: rgba(255, 255, 255, 0.02);
    border: 1px solid rgba(255, 255, 255, 0.08);
    border-radius: 18px;
    padding: 40px;
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 24px;
    align-items: center;
}

/* Features Section */
.features-section {
    display: flex;
    flex-direction: column;
    gap: 24px;
    width: 100%;
}

/* Individual Feature Item */
.feature-item {
    display: flex;
    align-items: center;
    padding: 20px;
    background: rgba(255, 255, 255, 0.02);
    border: 1px solid rgba(255, 255, 255, 0.05);
    border-radius: 18px;
    backdrop-filter: blur(100px);
    /* Optional: browser support is limited */
}

/* Icon styling */
.icon-background {
    width: 80px;
    height: 80px;
    background-color: #2C223B;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 20px;
}

.icon {
    width: 40px;
    height: 40px;
}

/* Feature Content */
.feature-content {
    display: flex;
    flex-direction: column;
}

.feature-title {
    font-weight: 600;
    font-size: 20px;
    color: #ECF1F0;
}

.feature-description {
    font-weight: 400;
    font-size: 16px;
    color: #B6B6B6;
    line-height: 1.5;
}

/* Decorative Background Elements */
.decorative-background .vector-element,
.decorative-background .ellipse-element {
    position: absolute;
    width: 100px;
    height: 100px;
    background: rgba(255, 255, 255, 0.02);
    border-radius: 50%;
    z-index: -1;
}

/* Example positioning of decorative elements */
.vector-element {
    top: 20%;
    left: 10%;
}

.ellipse-element {
    bottom: 20%;
    right: 15%;
}

/* Responsive adjustments for mobile screens */
@media (max-width: 768px) {
    .content-container {
        padding: 20px;
    }

    .feature-item {
        flex-direction: column;
        text-align: center;
        padding: 20px 10px;
    }

    .icon-background {
        margin: 0 auto 16px;
    }

    .feature-title {
        font-size: 18px;
    }

    .feature-description {
        font-size: 14px;
    }

    /* Adjust decorative elements for mobile */
    .vector-element {
        width: 80px;
        height: 80px;
        top: 5%;
        left: 5%;
    }

    .ellipse-element {
        width: 80px;
        height: 80px;
        bottom: 10%;
        right: 5%;
    }
}

/* css question ans form */



div.question-container>label {
    width: 100%;
    height: 10%;
    font-size: 15px;
    margin-bottom: 10px;
    display: inline-block;
}

div.question-container>input {
    margin-top: 10px;
    width: 100%;
    padding: 10px;
    margin-bottom: 10px;
    border: none;
    border-radius: 4px;
}

div.question-container>textarea {
    margin-top: 10px;
    width: 100%;
    /* Full width */
    padding: 10px;
    margin-bottom: 10px;
    /* Space between inputs */
    border: none;
    border-radius: 4px;
}

div.question-container>select {
    display: block;
    margin-top: 10px;
    width: 20%;
    /* Full width */
    padding: 10px;
    margin-bottom: 10px;
    /* Space between inputs */
    border: none;
    border-radius: 4px;
}
div.question-container input[type="radio"],
div.question-container input[type="checkbox"] {
    margin-right: 10px;
    transform: scale(1.2);
    cursor: pointer;
    width: auto;
}

div.question-container input[type="radio"] + label,
div.question-container input[type="checkbox"] + label {
    display: inline;
    margin-right: 20px;
    color: white;
}



div.question-container>input[type="radio"]::after,
div.question-container>input[type="checkbox"]::after {
    content: "";
    display: inline-block;
    margin-left: 10px; /* Space between the input and decorative element */
    width: 15px; /* Size of the decorative element */
    height: 15px;
    background-color: transparent; /* Change if you want a visual */
}



div.question-container {
    border-radius: 5px;
    padding-left: 40px;
    width: 80%;
    /* Adjust the width as needed */
    max-width: 600px;
    /* Maximum width */
    margin-bottom: 20px;
    color: white;
    text-align: left;
}

div.SubmitClass {
    display: flex;
    justify-content: left;
    align-items: center;
    height: 100px;
    padding-left: 7%;
}

/* buttons */
.button-container {
    display: flex;
    justify-content: space-between; /* Adjust spacing (space-between, center, etc.) */
    gap: 10px; /* Add space between buttons */
  }

/* Settings */
.settings-container {
    background-color: rgb(14 23 93 / 76%);
    border-radius: 10px;
    padding: 50px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}


.settings-section {
    margin-bottom: 30px;
    padding: 20px;
    background: rgba(255, 255, 255, 0.05);
    border-radius: 12px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
    text-align: left;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.settings-section:hover {
    transform: translateY(-5px);
    box-shadow: 0 6px 18px rgba(0, 0, 0, 0.3);
}
/* Loading */
.loading-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    z-index: 1000;
    color: white;
  }
  
  .spinner {
    border: 8px solid rgba(255, 255, 255, 0.3);
    border-top: 8px solid white;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    animation: spin 1s linear infinite;
    margin-bottom: 15px;
  }
  
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  .loading-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.7);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    color: white;
    font-size: 1.2rem;
  }
  
  .spinner {
    border: 4px solid rgba(255, 255, 255, 0.3);
    border-left-color: #ffffff;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    animation: spin 1s linear infinite;
  }
  
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  
  
